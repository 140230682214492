.bubbles {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  z-index: 1;
  pointer-events: none; /* Disable pointer events to allow interaction with underlying elements */
}

.bubble {
  position: absolute;
  bottom: -100px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  opacity: 0.9;
  animation: float 15s infinite; /* Increased animation duration for a slower effect */
}

@keyframes float {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(100px, -200px);
  }
  50% {
    transform: translate(-150px, -150px);
  }
  75% {
    transform: translate(200px, -300px);
  }
  100% {
    transform: translate(-100px, -500px);
  }
}
