/* Hide dots completely */
.slick-dots {
  display: none !important; /* Ensure dots are hidden */
}

/* Style for arrows */
.slick-prev,
.slick-next {
  position: absolute;
  top: 50%;
  z-index: 2; /* Ensure arrows are above the images and dots */
  transform: translateY(-50%);
  color: var(--arrow-color, white); /* Use theme color for arrows */
  font-size: 2em; /* Adjust size of arrows */
}

.slick-prev {
  left: 15px; /* Position the previous arrow */
}

.slick-next {
  right: 15px; /* Position the next arrow */
}

.slick-prev .slick-arrow,
.slick-next .slick-arrow {
  font-size: 2em;
  color: var(--arrow-color, white); /* Use theme color for arrows */
}
