.header {
  padding: 1rem;
  text-align: center;
  transition: background-color 0.3s, color 0.3s;
}

.light-theme .header {
  background-color: #E0E0E0;
  color: #212121;
}

.dark-theme .header {
  background-color: #333333;
  color: #FFFFFF;
}
